import { useState, useEffect } from 'react'
import Post from './Post.js'

const HomePage = () => {
    const [postsList, setPostsList] = useState([])

    useEffect(() => {
        document.title =  "My POSTs"
        fetch(process.env.BACKEND_LINK)
            .then(response => {
                if (response != null) {
                    return response.json()
                } else {
                    return []
                }})
            .then(data => {
                setPostsList(data.reverse())
            })

    }, []);

    return (  
        <div className="home-page">
            <div className="instruction-box">
                <p>View posts by clicking on them, or write your own post!</p>
            </div>
            <div className="all-posts-list">
                { postsList.map((post) => (
                    <Post post={post}></Post>
                ))}
            </div>
        </div>
    );
}
 
export default HomePage;    
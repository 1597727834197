import { useState } from 'react'

const Post = (props) => {
    const post = props.post
    const preview = post.content.split(" ").slice(0, 5).join(" ") + "..."
    const [currentlyHidden, setCurrentlyHidden] = useState(true)
    const [previewStyle, setPreviewStyle] = useState({display: "block"})
    const [contentStyle, setContentStyle] = useState({display: "none"})

    const togglePost = () => {
        if (!currentlyHidden) {
            setPreviewStyle({display: "block"})
            setContentStyle({display: "none"})         
        } else {
            setPreviewStyle({display: "none"})
            setContentStyle({display: "block"})
        }
        setCurrentlyHidden(!currentlyHidden)
    }
    
    return ( 
        <div className="post-view" onClick={() => togglePost()}>
            <div className="post-title-div">
                <h2>{post.title + " "}</h2><h3>{post.username}</h3>
            </div>
            <p className="post-preview" style={previewStyle}>
                <b>[Preview:] </b>
                {preview}
            </p>
            <p className="post-content" style={contentStyle}>{post.content}</p>
        </div>
     );
}
 
export default Post;
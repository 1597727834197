import './App.css';
import NavBar from './NavBar';
import HomePage from './HomePage';
import WritePost from './WritePost';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar/>
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage/>}></Route>
            <Route path="/create" element={<WritePost/>}></Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

const NavBar = () => {
    return (
        <nav className = "navbar">
            <a className="site-logo" href="/"> My POSTs </a>
            <div className = "links">
                <a className="create-link" href="/create">Write a Post!</a>
            </div>
        </nav>
    );
}
export default NavBar
import { useState } from "react";

const WritePost = () => {
    const [title, setTitle] = useState("")
    const [username, setUsername] = useState("")
    const [content, setContent] = useState("")

    const submitPost = () => {
        const post = {title, username, content}
        fetch(process.env.BACKEND_LINK, {
            method: "POST", 
            headers: {"Content-Type": "application/json"}, 
            body: JSON.stringify(post)
        }).then(() => {
            
        })
    }

    return ( 
        <div className="write-post">
            <h2>Write a new post!</h2>
            <form onSubmit={submitPost}>
                <label>Title:</label>
                <input
                    type="text"
                    required
                    placeholder="Title your post"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <label>User:</label>
                <input
                    type="text"
                    placeholder="Add your username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <label>Content:</label>
                <textarea 
                    placeholder="Write your post"
                    required
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                ></textarea>  
                <button>Add post</button>
            </form>
        </div>
    );
}
 
export default WritePost;